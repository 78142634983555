import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Layout, Seo, FullBleed } from 'components';
import { Link } from 'gatsby';
import BottlesImg from 'images/vasostrict-bottles.png';
import CheckMarkImg from 'images/check-mark.png';
// import page style
import './index.scss';

const IndexPage = () => {
  const referencesHtml = (
    <p>
      <span className="bold">References: 1.</span> Vasostrict<sup>&reg;</sup>.
      Prescribing Information. Endo USA, Inc.&nbsp;
      <span className="bold">2.</span> Data on file. DOF-VS-02. Endo USA, Inc.;
      September 20, 2023.&nbsp;
      <span className="bold">3.</span> American Society of Health-System
      Pharmacists. ASHP guidelines on preventing medication errors in hospitals.{' '}
      <i>Am J Health-Syst Pharm</i>. 2018;75(19):1493-1517.
    </p>
  );

  return (
    <Layout
      indication="patient"
      className="home"
      referencesHtml={referencesHtml}
    >
      <Seo pageTitle="Home" />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          {/** Actuall content goes here */}
          <FullBleed className="container-full-bleed full-bleed-hero">
            <div className="container-index-hero">
              <Row>
                <Col xs={12} lg={6} xl={8}>
                  <div className="hero-text-and-button">
                    <h1>
                      <span>
                        Up to 12-Month Shelf Life at Room Temperature
                        <sup>1</sup>*
                      </span>
                      24-Month Total Shelf Life<sup>2</sup>
                    </h1>

                    <p className="subhead bold">
                      Available in two concentrations
                    </p>
                    <p className="dosages">
                      20 units/100 mL in 5% dextrose
                      <br />
                      40 units/100 mL in 5% dextrose
                    </p>

                    <button className="button-workflow-scenarios">
                      <Link to="/workflow-scenarios">Workflow Scenarios</Link>
                    </button>
                    <table className="footnote-home">
                      <tbody>
                        <tr>
                          <td className="footnote-text">*</td>
                          <td className="footnote-text">
                            Room temperature (20°C to 25°C or 68°F to 77°F) upon
                            removal from refrigeration. Recommended storage is
                            under refrigerated conditions from 2°C to 8°C (36°F
                            to 46°F); do not freeze.<sup>1,2</sup>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col xs={12} lg={6} xl={4} className="col-hero-bottles">
                  <div className="container-hero-bottles">
                    <img
                      className="hero-bottles"
                      src={BottlesImg}
                      alt="Vasostrict® product available in two concentrations"
                    />
                    <p className="text-not-actual-size">Not actual size.</p>
                  </div>
                </Col>
              </Row>
            </div>
          </FullBleed>

          <FullBleed className="container-full-bleed full-bleed-now-available">
            <div className="container-now-available">
              <Row>
                <Col xs={12} md={12} className="col-now-available">
                  <h2 className="h1 text-ready-to-use">
                    The first and only <nobr>FDA-approved</nobr> RTU vasopressin
                    with up to 12 months of room temperature stability
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="container-text-now-available-left-column">
                    <p>
                      Vasostrict<sup>&reg;</sup> is a sterile, aqueous solution
                      of synthetic arginine vasopressin for intravenous
                      administration.
                      <sup>1</sup> Vasopressin is a polypeptide hormone.
                      Vasostrict<sup>&reg;</sup> is indicated to increase blood
                      pressure in adults with vasodilatory shock who remain
                      hypotensive despite fluids and catecholamines.
                    </p>
                    <p>
                      Vasostrict<sup>&reg;</sup> premixed solution comes in
                      ready-to-use, single-dose vials that do not require
                      further dilution prior to administration.<sup>1</sup>
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <table className="now-available-checklist">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>
                          May offer enhanced inventory management with{' '}
                          <strong>
                            up to <nobr>12-month</nobr> shelf life at room
                            temperature
                          </strong>
                          <sup>1,2†</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>Preservative-free</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>Available through your wholesaler</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>
                          Does not require compounding, diluting, mixing, or
                          transferring, which may reduce waste and chance of
                          preparation error<sup>3</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>
                          Compatible with most automated dispensing machines
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            className="now-available-check-mark"
                            src={CheckMarkImg}
                          />
                          &nbsp;
                        </td>
                        <td>
                          Aligns with medication strategies of{' '}
                          <span className="italic">
                            ASHP Guidelines on Preventing Medication Errors in
                            Hospitals
                          </span>
                          <sup className="shift-right">3</sup>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="col-now-available">
                  <button className="button-workflow-scenarios">
                    <Link to="/workflow-scenarios">Workflow Scenarios</Link>
                  </button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <table className="footnote-home">
                    <tbody>
                      <tr>
                        <td className="footnote-text">
                          <sup>†</sup>
                        </td>
                        <td className="footnote-text">
                          Room temperature (20°C to 25°C or 68°F to 77°F) upon
                          removal from refrigeration. Recommended storage is
                          under refrigerated conditions from 2°C to 8°C (36°F to
                          46°F); do not freeze.<sup>1,2</sup>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </FullBleed>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
